import axios from 'axios'
import { useAuth } from '@/stores/auth'


const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: process.env.NODE_ENV === 'production'
    ? '/api'
    : ''
})

axiosInstance.interceptors.request.use((config) => {

  const auth = useAuth()
  const tokens = auth.token
  if (!tokens) {
    return config
  }
  config.headers.Authorization = `${tokens}`
  return config
})

axiosInstance.interceptors.response.use(response => {

  return response
}, function (error) {
  if (401 === error.response.status) {
    const auth = useAuth();
    auth.clear();
    window.location = '/'
  }})

export default axiosInstance
