import { createRouter, createWebHistory } from 'vue-router'
import { useAuth } from '@/stores/auth'
const routes = [
  {
    path: '/',
    name: 'loginmana',
    component: () => import('../components/LoginMana.vue')
  },
  {
    path: '/menu',
    name: 'menuadmin',
    component: () => import('../components/MenuAdmin.vue'), 
    children: [
      {
          path: '/nuevocliente',
          name: 'nuevocliente',
          component: () => import('../components/ClienteNuevo.vue'), 
      },
      {
        path: '/listarcliente',
        name: 'listarcliente',
        component: () => import('../components/ClienteListar.vue'), 
      },
      {
        path: '/editarcliente/:id',
        name: 'editarcliente',
        component: () => import('../components/ClienteEditar.vue'), 
      },
      {
        path: '/servicionuevo',
        name: 'servicionuevo',
        component: () => import('../components/ServicioNuevo.vue'), 
      },
      {
        path: '/serviciorenovacion/:id',
        name: 'serviciorenovacion',
        component: () => import('../components/ServicioNuevo.vue'), 
      },
      {
        path: '/serviciolistar',
        name: 'serviciolistar',
        component: () => import('../components/ServicioListar.vue'), 
      },
      {
        path: '/pagosregistrados',
        name: 'pagosregistrados',
        component: () => import('../components/PagosRegistrados.vue'), 
      },
      {
        path: '/tiposervicionuevo',
        name: 'tiposervicionuevo',
        component: () => import('../components/TipoServicioNuevo.vue'), 
      },
      {
        path: '/tiposerviciolistar',
        name: 'tiposerviciolistar',
        component: () => import('../components/TipoServicioListar.vue'), 
      },
      {
        path: '/editartiposervicio/:id',
        name: 'editartiposervicio',
        component: () => import('../components/TipoServicioNuevo.vue'), 
      },
      {
        path: '/pagonuevo',
        name: 'pagonuevo',
        component: () => import('../components/PagoNuevo.vue'), 
      },
  ],
    meta: { auth: true }
  },  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta?.auth) {
    const auth = useAuth();
      if (auth.token && auth.user) {
          next();
      } else {
        next({ name: 'home' });
      }}else{
        next();
      }
})

export default router
