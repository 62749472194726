import {defineStore} from 'pinia';
import {ref} from 'vue';
import http from '@/servicio/http.js';

export const  useAuth = defineStore('auth', () => {
const token = ref(localStorage.getItem('token'));
const user  = ref(localStorage.getItem('user'));

function setToken(tokenValue){
    localStorage.setItem('token',tokenValue);
    token.value=tokenValue;
}

function setUser(userValue){
    localStorage.setItem('user',userValue);
    user.value=userValue;
}

function clear(){
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    token.value='';
    user.value='';

}

async function checkToken(){
    try {
        const {data} = await http.get('/api/auth/'+token.value);
        return data;
    } catch (error) {
        console.log(error.response.data);
    }
}

return {
    setToken, setUser, token, user, checkToken,clear
}

});